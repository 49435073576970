import { Stack, Typography } from '@mui/material';
import { ConductSelectTypesSelectedValue, useGetConductTypesForSchoolQuery } from '@schooly/api';
import { ArchiveIcon, TagSelect } from '@schooly/style';
import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DottedDivider } from '../PropertyType/DottedDivider.styled';
import { SelectContentSkeleton } from '../SelectContentSkeleton';

type ConductTypeOptionsSelectContentProps = {
  schoolId: string;
  selectedValue: ConductSelectTypesSelectedValue[];
  query?: string;
  onSelectOption: (conductTypeId: string, option: string) => void;
};

export const ConductTypeOptionsSelectContent: FC<ConductTypeOptionsSelectContentProps> = ({
  schoolId,
  query,
  selectedValue,
  onSelectOption,
}) => {
  const { data } = useGetConductTypesForSchoolQuery(schoolId, { refetchOnMount: 'always' });

  const selectOptions = data?.filter((ct) => ct.select_type_options?.length);

  const queryOptions = useMemo(
    () =>
      query
        ? selectOptions
            ?.map((type) => ({
              ...type,
              select_type_options: type.select_type_options?.filter((option) =>
                option.label.toLowerCase().includes(query.toLowerCase()),
              ),
            }))
            .filter((type) => type.select_type_options?.length)
        : selectOptions,
    [query, selectOptions],
  );

  if (!data) return <SelectContentSkeleton />;

  if (!queryOptions?.length)
    return (
      <Typography p={1}>
        <FormattedMessage id="input-NoOptionsFound" />
      </Typography>
    );

  return (
    <Stack gap={2} m={1}>
      {queryOptions.map((option) => {
        const activeSelectOptions = option.select_type_options?.filter(
          (selectOption) => !selectOption.archived,
        );

        const archivedSelectOptions = option.select_type_options?.filter(
          (selectOption) => selectOption.archived,
        );

        return (
          <Stack gap={2}>
            {!!activeSelectOptions?.length && (
              <Stack gap={0.5}>
                <Typography variant="h4">{option.name}</Typography>

                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {activeSelectOptions.map((selectOption) => {
                    const isSelected = selectedValue.find(
                      (v) =>
                        v.conduct_type_id === option.id &&
                        v.conduct_type_options.includes(selectOption.label),
                    );

                    return (
                      <TagSelect
                        key={selectOption.label}
                        label={selectOption.label}
                        variant={isSelected ? 'filled' : undefined}
                        onClick={() => onSelectOption(option.id, selectOption.label)}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            )}

            {!!archivedSelectOptions?.length && (
              <>
                <DottedDivider />

                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {archivedSelectOptions.map((selectOption) => {
                    const isSelected = selectedValue.find(
                      (v) =>
                        v.conduct_type_id === option.id &&
                        v.conduct_type_options.includes(selectOption.label),
                    );

                    return (
                      <TagSelect
                        key={selectOption.label}
                        label={selectOption.label}
                        variant={isSelected ? 'filled' : undefined}
                        icon={<ArchiveIcon />}
                        onClick={() => onSelectOption(option.id, selectOption.label)}
                      />
                    );
                  })}
                </Stack>
              </>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
